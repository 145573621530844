<template>
    <common-form
            ref="form"
            url="device/update"
            :edit="true"
            :items="items"
            :beforeRequest="beforeRequest"
            @success="$emit('success')"></common-form>
</template>

<script>

    export default {
        data() {
            return {
                iotId:"",
                edit: false,
            }
        },
        computed: {
            items() {
                return [
                    {
                        type: "input",
                        name: "设备名称",
                        key: "nickname",
                        required:true
                    },
                    {
                        type: "input",
                        name: "设备MAC",
                        key: "mac",
                        disabled:true
                    },
                    {
                        type: "location",
                        name: "设备地址",
                        key: "location",
                    },
                ]
            }
        },
        methods: {
            show(model = {}) {
                this.edit = model.id > 0
                this.iotId=model.iotId
                model = {...model}
                model.location={
                    address:model.address,
                    latitude:model.latitude,
                    longitude:model.longitude
                }
                this.$refs.form.show(model)
            },

            beforeRequest(model){
                return {
                    iotId:this.iotId,
                    nickname:model.nickname,
                    address:model.location.address,
                    latitude:model.location.latitude,
                    longitude:model.location.longitude,
                }
            }
        }

    }
</script>
<style lang="less" scoped>
    .block {
        padding: 5px;
        background: #FFF;
        border-radius: 5px;
        border: 1px solid #F0F0F0;

        .item-container {
            width: 25%;
            float: left;
            padding: 5px;
        }

        .item {
            border-radius: 5px;
            background: #F8F8F8;
            padding: 5px 15px;
        }

    }
</style>
